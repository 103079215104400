import React, { Component } from "react";
import { FormGroup, Label, FormFeedback, Input } from "reactstrap";
import ArrowDown  from '../../../assets/svg/arrow-down-black.svg'
import Isvg from 'react-inlinesvg';

class SelectMultipleInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.dropdownRef = React.createRef();
    }

    componentDidUpdate(_, prevState) {
        if (this.state.isOpen && !prevState.isOpen) {
            document.addEventListener("mousedown", this.handleClickOutside);
        } else if (!this.state.isOpen && prevState.isOpen) {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    handleClickOutside = (event) => {
        if (
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target)
        ) {
            this.setState({ isOpen: false });
        }
    };

    handleSelect = (item) => {
        console.log(this.props.value)
        if (this.props.onChange) {
            const itemsToChange = [...this.props.value];
            if (itemsToChange.includes(item)) {
                const newList = itemsToChange.filter(itemToCheck => itemToCheck !== item);
                this.props.onChange(newList);
            } else {
                itemsToChange.push(item);
                this.props.onChange(itemsToChange);
            }
        }
    };


    render() {
        const { label, values, error, name,value, placeholder } = this.props;
        const { isOpen } = this.state;

        return (
            <FormGroup className="group-container">
                {label ? (
                    <Label size="sm" className={error ? "required" : ""}>
                        {label}
                    </Label>
                ) : null}
                <div className="dropdown" ref={this.dropdownRef}>
                    <Input
                        size="sm"
                        name={name}
                        invalid={!!error}
                        type={"text"}
                        value={placeholder}
                        onClick={this.toggleDropdown}
                        readOnly
                        className="cursor-pointer color-input-blue position-relative"
                    > </Input>
                    <span  onClick={this.toggleDropdown} className='select-input-arrow cursor-pointer'><Isvg src={ArrowDown} /></span>
                    {isOpen && (
                        <div className="dropdown-menu show w-100 dropdown-menu-control">
                            {values && values.length > 0 ? (
                                values.map((item, idx) => (
                                    <div
                                        key={idx}
                                        className={`${value.includes(item) ? 'bg-ee-blue ' : ''} dropdown-item cursor-pointer w-100`}
                                        onClick={() => this.handleSelect(item)}
                                        title={item}
                                    >
<span >
  {item}
</span>
                                    </div>
                                ))
                            ) : (
                                <div className="dropdown-item disabled">
                                    No options
                                </div>
                            )}
                        </div>
                    )}
                </div>


                {error ? <FormFeedback>{error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default SelectMultipleInput;
