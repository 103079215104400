import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/formBuilder'
import { required } from '../../components/forms/validation'
import Search from '../../components/search'
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import rightIcon from '../../assets/svg/arrow-right.svg'

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            menu: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
        setTimeout(() => {

            let menu1 = this.state.menu.filter((item) => item.uniqueID == '2')[0];
            let menu2 = this.state.menu.filter((item) => item.uniqueID == '3')[0];
            let menu3 = this.state.menu.filter((item) => item.uniqueID == '1')[0];

            let arr = [];
            let obj2 = {
                name: "Kadrovska",
                value: 1,
                menu: [menu1._id]
            };
            let obj1 = {
                name: "Opsti dokumenti",
                value: 0,
                menu: [menu1._id, menu3._id]
            };
            let obj3 = {
                name: "Cjenovnici",
                value: 2,
                menu: [menu2._id]
            };
            arr.push(obj1);
            arr.push(obj2);
            arr.push(obj3);
            this.setState({ selectOption: arr });
        }, 500);


    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.page) {
            this.setState({ lastPage: this.props[0].location.state.page })
        }

        this.get()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props?.category && this.props.category !== prevProps?.category) {
            this.setState({ selectedCategory: this.props.category });
        }
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
        if (prevProps.menu && prevProps.menu != this.props.menu) {
            let menu3 = this.state.menu.filter((item) => item.uniqueID == '1')[0];
            console.log(this.props.menu)
            if (menu3._id == this.props.menu) {
                this.props.changeCategory(0);
            } else {
                this.props.changeCategory(null);
            }

        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props[0].match.params.id == 'new') {
                    fetch(API_ENDPOINT + '/data/documents/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push('/documents/list')
                        })
                } else {
                    fetch(API_ENDPOINT + '/data/documents/' + this.props[0].match.params.id, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            this.props[0].history.push({
                                pathname: '/documents/list',
                                state: { page: this.state.lastPage }
                            })
                        })
                }
            }
        )
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/menu/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        return (
            <div className='page'>
                <Container className='dashboard'>

                    {/* <Row>
                        <Col lg='9'>
                            <Search />
                        </Col>
                        <Col lg='3'>
                            <div className='component notifications'>
                                <div className="component-wrap">
                                    <Isvg src={notification} />
                                    <div className='profile'>
                                        <span className='name color-black'>
                                            {this.props.uData.username}
                                        </span>
                                        <Isvg src={profile} className='profile-image' />
                                        <Isvg src={moreIcon} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <FormBuilder

                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.initialValues}

                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 9
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    //HEADER
                                                    {//naslov forme
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'div',
                                                                className: 'form-header',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Dokument'
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    //END HEADER
                                                    //FORMA za unos vijesi
                                                    ...(
                                                        (this.props.uData?.super === true ||
                                                            this.props?.uData.menuObjects.some(object => object._id === "5ff4439d47cd2e2fcce2677a"))
                                                            ? [
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 2,
                                                                                sm: 2,
                                                                                xs: 2
                                                                            },
                                                                            className: 'folder-toggle',
                                                                            children: [
                                                                                {
                                                                                    type: 'switch',
                                                                                    name: 'inFolder',
                                                                                    label: 'Folder'
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                            : []
                                                    ),
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                            type: 'text',
                                                            name: 'name',
                                                            label: 'Naziv'
                                                        }
                                                        ]

                                                    },
                                                    ...((this.props.inFolder !== undefined && this.props.inFolder !== null
                                                        ? this.props.inFolder
                                                        : this.state.initialValues?.inFolder === true)

                                                        ? [{
                                                            type: 'col',
                                                            width: {
                                                                lg: 6, sm: 12, xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'select',
                                                                    name: 'menu',
                                                                    multiple: false,
                                                                    values: this.state.menu.filter(item => {
                                                                        // if (this.props.uData.menu && item.link != "ljudski-resursi") {
                                                                        for (let i = 0; i < this.props.uData.menu.length; i++) {
                                                                            if (this.props.uData.menu[i] == item._id) {
                                                                                return true;
                                                                            }
                                                                        }
                                                                        // }
                                                                    }).map(item => {
                                                                        return {
                                                                            name: item.name,
                                                                            value: item._id
                                                                        }
                                                                    }),
                                                                    label: 'Meni',
                                                                    validate: required('Meni je obavezan!')
                                                                },
                                                            ]
                                                        },
                                                            {
                                                                type: 'col',
                                                                width: {
                                                                    lg: 6, sm: 12, xs: 12
                                                                },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'category',
                                                                        multiple: false,
                                                                        values: this.state.categories && this.state.categories.filter(item => this.props.menu == item.menu).map(item => {
                                                                            return {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }

                                                                        }),
                                                                        label: 'Kategorija',
                                                                        validate: required('Kategorija je obavezna!')
                                                                    },
                                                                ]
                                                            },
                                                            ...((this.state.initialValues?.category && this.state.folders && this.state.folders.some((folder) => {return folder.category === this.state.initialValues.category})) ||
                                                                (this.state.selectedCategory && this.state.folders && this.state.folders.some((folder) => folder.category === this.state.selectedCategory))
                                                                    ? [
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 6, sm: 12, xs: 12
                                                                            },
                                                                            children: [
                                                                                {
                                                                                    type: 'select',
                                                                                    name: 'folder',
                                                                                    multiple: false,
                                                                                    values: this.state.folders.filter((folder) => {
                                                                                        if (this.state.selectedCategory) {
                                                                                            return folder.category === this.state.selectedCategory;
                                                                                        }
                                                                                        return folder.category === this.state.initialValues.category;
                                                                                    }).map((item) => ({
                                                                                        name: item.folder,
                                                                                        value: item._id,
                                                                                    })),
                                                                                    label: 'Folder',
                                                                                    validate: required('Kategorija je obavezna!')
                                                                                },
                                                                            ]
                                                                        }
                                                                    ]
                                                                    : []
                                                            ),] : [{
                                                            type: 'col',
                                                            width: {
                                                                lg: 12, sm: 12, xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'div',
                                                                    className: 'component',
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'menu',
                                                                            multiple: false,
                                                                            values: this.state.menu.filter(item => {
                                                                                if (this.props.uData.menu && item.uniqueID != '4') {
                                                                                    for (let i = 0; i < this.props.uData.menu.length; i++) {
                                                                                        if (this.props.uData.menu[i] == item._id) {
                                                                                            return true;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }).map(item => {
                                                                                return {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                            }),
                                                                            label: 'Meni'
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'div',
                                                                    className: 'component',
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'category',
                                                                            multiple: false,
                                                                            values: this.state.selectOption && this.state.selectOption.filter(item => item.menu.filter(el => this.props.menu == el).length).map(item => {

                                                                                return {
                                                                                    name: item.name,
                                                                                    value: item.value
                                                                                }

                                                                            }),

                                                                            // this.state.selectOption && this.state.selectOption.map(item => {
                                                                            //     return {
                                                                            //         name: item.name,
                                                                            //         value: item.value
                                                                            //     }
                                                                            // })
                                                                            // ,


                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        ]),
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'submit-button',
                                                                className: 'main-form-button button-1 form-disabled-button',
                                                                text: this.props[0].match.params.id != 'new' ? 'Izmjeni dokument' : 'Dodaj dokument',
                                                                disabled: (this.props?.inFolder === true || this.state.initialValues?.inFolder === true) && (!this.props.uData?.super === true || !this.props?.uData.menuObjects.some(object => object._id === "5ff4439d47cd2e2fcce2677a"))
                                                            }
                                                        ]
                                                    }

                                                ]
                                            }]
                                        }
                                        ]
                                    },
                                    //END FORM za unos vijesti
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3
                                        },
                                        children: [
                                            {//vrijeme objave
                                                type: 'div',
                                                className: 'component customer-age',
                                                children: [
                                                    {
                                                        type: 'h5',
                                                        text: 'Fajl',
                                                        className: 'component-header'
                                                    },
                                                    {
                                                        type: 'image',
                                                        height: 240,
                                                        name: 'icon',
                                                    },

                                                ]
                                            },

                                        ]
                                    }
                                ]
                            }
                        ]}
                    ></FormBuilder>
                </Container>
            </div >
        )
    }
}

// export default Page(Form)
const selector = formValueSelector('form');

export default connect(state => {
    return { menu: selector(state, 'menu'), category: selector(state, 'category'), inFolder: selector(state, 'inFolder') }

}, {
    changeCategory: value => change("form", "category", value),


})(Page(Form));
