import React, { Component } from 'react'
import Page from '../../containers/page'
import {generateSearchLink, getSearchParams} from "../../helpers/linkHelper";
import {Col, Container, Row} from "reactstrap";
import Search from "../../components/search";
import ListBuilder from "../../components/listBuilder";
import Isvg from "react-inlinesvg";
import DeleteModal from "../../components/deleteModal";
import PreviewModal from "../../components/previewSuggestion";
import {Link} from "react-router-dom";
import editIcon from "../../assets/svg/edit.svg";
import garabageIcon from "../../assets/svg/garabage.svg";
import {API_ENDPOINT} from "../../constants";
class List extends Component {
    constructor(props) {

        super(props)
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
            access: false
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }
    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/folders/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }
    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get();

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }
    updateParams = (name, value, restart = false) => {

        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = encodeURI(value)
                this.setState({ params: obj })
            } else {

                let obj = this.state.params
                obj[name] = encodeURI(value)

                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }
    render(){
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        return (<div className='page'>

            <Container className='dashboard'>
                <Row>
                    <Col lg='12'>
                        <Search updateParams={this.updateParams}/>
                        <div className='panel'>
                            <div className='main-table-header'>
                                <h5 className='component-header'>Lista</h5>
                                <button className='add-news'>
                                    <Link to='/folders/new'>
                                        Dodaj folder
                                    </Link>
                                </button>
                            </div>

                            <ListBuilder
                                loading={this.state.loading && false}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    {
                                        type: 'text',
                                        class: 'labelbam',
                                        name: 'folder',
                                        sortLabel: 'Po nazivu',
                                        allowSort: true,

                                    }

                                ]}
                                rawItems={this.state.items}
                                items={


                                    this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            title: item.title,
                                        }

                                    })

                                }
                                actions={[
                                    {
                                        type: 'functionAction',
                                        render: (item) => <Link to={
                                            {
                                                pathname: `/folders/${item._id}`,
                                                state: { page: params && params.page }
                                            }


                                        }><Isvg src={editIcon} />
                                        </Link>,
                                    },
                                    {
                                        component: <Isvg src={garabageIcon} className='edit-icon' />,
                                        onClick: (item) => this.setState({ deleteModal: item })
                                    },
                                ]}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                            >
                                Obrisi <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                            </DeleteModal>
                            <PreviewModal
                                isOpen={this.state.previewModal}
                                toggle={() => this.setState({previewModal: null})}
                                handler={() => {
                                    this.delete(this.state.previewModal._id);
                                    this.setState({previewModal: null})
                                }}
                                item={this.state.previewModal}
                            >
                            </PreviewModal>

                        </div>
                    </Col>
                </Row>
            </Container>

        </div>)
    }
}

export default Page(List);